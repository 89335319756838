// src/App.js
import './App.css';
import { AuthProvider } from './components/routing/AuthContext';
import { AppRouter } from "./components/routing/AppRouter";
import { AppDataProvider } from './components/graphicComponents/mainMenu/AppDataProvider';

function App() {
  return (
    <AuthProvider>

      <AppDataProvider>
        <div className="App">
          <AppRouter />
        </div>
      </AppDataProvider>
      
    </AuthProvider>
  );
}

export default App;
