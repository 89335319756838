import React, { createContext, useContext, useCallback } from 'react';
import { useAppData } from '../mainMenu/AppDataProvider';  // Importing AppDataContext

const CommentContext = createContext();

// Custom hook for using context
export const useComments = () => {
  const context = useContext(CommentContext);
  if (context === undefined) {
    throw new Error('useComments must be used within a CommentsProvider');
  }
  return context;
};

export const CommentsProvider = ({ children }) => {
  const { appData, setAppData } = useAppData();  // Access the general context data
  const { comments } = appData;

  // Backend URL
  const baseURL = 'https://seed-alumni.org/api/v1/comments';
  //const baseURL = 'http://localhost:3001/api/v1/comments'; // Development URL

  const fetchComments = useCallback(async () => {
    try {
      const response = await fetch(`${baseURL}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAppData(prevData => ({ ...prevData, comments: data }));  // Update general context
      return data;
    } catch (error) {
      console.error("Failed to fetch comments:", error);
      return [];
    }
  }, [setAppData]);

  const addComment = async (commentDto) => {
    try {
      const response = await fetch(`${baseURL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentDto),
      });
      if (response.ok) {
        const updatedComments = await fetchComments();  // Refetch all comments
        setAppData(prevData => ({ ...prevData, comments: updatedComments }));  // Update general context
      }
      return response;
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  const updateComment = async (id, commentDto) => {
    try {
      const response = await fetch(`${baseURL}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentDto),
      });
      if (response.ok) {
        const updatedComments = await fetchComments();  // Refetch all comments
        setAppData(prevData => ({ ...prevData, comments: updatedComments }));  // Update general context
      }
      return response;
    } catch (error) {
      console.error("Failed to update comment:", error);
    }
  };

  const deleteComment = async (id) => {
    try {
      const response = await fetch(`${baseURL}/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const updatedComments = await fetchComments();  // Refetch all comments
        setAppData(prevData => ({ ...prevData, comments: updatedComments }));  // Update general context
      }
      return response;
    } catch (error) {
      console.error("Failed to delete comment:", error);
    }
  };

  return (
    <CommentContext.Provider value={{ comments, addComment, updateComment, deleteComment, fetchComments }}>
      {children}
    </CommentContext.Provider>
  );
};
