import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../../../styles/graphicComponents/profile/OwnProfileDetails.css';
import { useProfiles } from '../profile/ProfilesContext'; // Import the ProfilesContext
import { useAppData } from '../mainMenu/AppDataProvider'; // Import the general context

const OwnProfileDetails = () => {
    const { user, isAuthenticated } = useAuth0();
    const { profile, updateProfile } = useProfiles(); // Get profile and updateProfile from ProfilesContext
    const { appData, setAppData } = useAppData(); // Access the general context
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        role: '',
        team: '',
        participationYear: '',
        picture: '',
        major: '',
        university: '',
        about: '',
        interests: '',
        email: '',
        linkedin: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    //const baseURL = 'http://localhost:3001/api/v1'; // Development
    const baseURL = 'https://seed-alumni.org/api/v1'; // Production

    // Load profile data from currentUser in general context
    useEffect(() => {
        const currentUserProfile = appData.currentUser;
        if (currentUserProfile) {
            setFormData(currentUserProfile);
            setIsEditing(false); // Ensure editing mode is false on load
        }

        // Introduce a 1-second delay before showing the profile
        const timer = setTimeout(() => {
            setIsLoading(false); // Stop the loading after 1 second
        }, 1000);

        return () => {
            clearTimeout(timer); // Clean up the timer
            setIsEditing(false); // Ensure editing mode is false when component unmounts
        };
    }, [appData.currentUser]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setFormData({
                ...formData,
                picture: URL.createObjectURL(file),  // Preview the selected image
            });
        }
    };

    const handleUpdateProfile = async () => {
        try {
            if (selectedFile) {
                const uploadFormData = new FormData();
                uploadFormData.append('file', selectedFile);

                const uploadResponse = await fetch(`${baseURL}/uploads`, {
                    method: 'POST',
                    body: uploadFormData,
                });

                if (!uploadResponse.ok) {
                    throw new Error(`File upload failed! status: ${uploadResponse.status}`);
                }

                const fileData = await uploadResponse.json();
                formData.picture = `${baseURL}/uploads/${fileData.filename}`; // Adjust path as needed
            }

            await updateProfile(formData);

            // Update the general context with the updated profile
            setAppData(prevData => ({
                ...prevData,
                currentUser: formData,
                profiles: prevData.profiles.map(p => p.id === formData.id ? formData : p),
            }));

            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update profile:", error);
        }
    };

    if (isLoading) {
        return (
        <div className="fun-loading-animation">
            <div className="ball"></div>
                <p>Loading... Please wait!</p>
      </div>
        );
    }

    return (
        <div className={`profile-section-main-container ${isEditing ? 'editing-mode' : ''}`}>
            {profile && !isEditing ? (
                <div className="profile-content-container">
                    <div className="profile-header">
                        <h1>{profile.name}</h1>
                        <img className="profile-picture" src={profile.picture} alt="Profile" />
                        <p id='profile-university-p'>{profile.university}</p>
                        <p>{profile.major}</p>
                        <button id="edit-profile-button" onClick={() => setIsEditing(true)}>Edit Profile</button>
                    </div>
                    <div className="profile-body">
                        <div className="profile-about">
                            <h2>About me</h2>
                            <p id="participant-text">{profile.about}</p>
                        </div>
                        <div className="profile-interests">
                            <h2>Interests</h2>
                            <p id="participant-text">{profile.interests}</p>
                        </div>
                        <div className="profile-contact">
                            <h2>Contact</h2>
                            <p><strong>Email:</strong> {profile.email}</p>
                            <p><strong>LinkedIn:</strong> <a href={profile.linkedin}>{profile.linkedin}</a></p>
                        </div>
                        <div className="profile-team">
                            <h2>My Team</h2>
                            <p id="participant-text">{profile.team}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="profile-content-container">
                    <div className="profile-header">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                        />
                        <img className="profile-picture" src={formData.picture} alt="Profile" />
                        <label htmlFor="picture">Profile Picture</label>
                        <input
                            type="file"
                            name="picture"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="profile-body">
                        <div>
                            <label htmlFor="major">Major</label>
                            <input
                                type="text"
                                name="major"
                                value={formData.major}
                                onChange={handleChange}
                                placeholder="Major"
                            />
                        </div>
                        <div>
                            <label id="university-name" htmlFor="university">University</label>
                            <input
                                type="text"
                                name="university"
                                value={formData.university}
                                onChange={handleChange}
                                placeholder="University"
                            />
                        </div>
                        <div className="profile-about">
                            <label htmlFor="about">About me</label>
                            <textarea
                                name="about"
                                value={formData.about}
                                onChange={handleChange}
                                placeholder="About"
                            />
                        </div>
                        <div className="profile-interests">
                            <label htmlFor="interests">Interests</label>
                            <textarea
                                name="interests"
                                value={formData.interests}
                                onChange={handleChange}
                                placeholder="Interests"
                            />
                        </div>
                        <div className="profile-contact">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Email"
                            />
                            <label htmlFor="linkedin">LinkedIn</label>
                            <input
                                type="text"
                                name="linkedin"
                                value={formData.linkedin}
                                onChange={handleChange}
                                placeholder="LinkedIn"
                            />
                        </div>
                        <div className="profile-team">
                            <label htmlFor="email">My Team</label>
                            <input
                                type="text"
                                name="team"
                                value={formData.team}
                                onChange={handleChange}
                                placeholder="Current or Former Team Name"
                            />
                        </div>    

                    </div>
                    <div className="profile-buttons">
                        <button onClick={handleUpdateProfile}>Save</button>
                        <button className="cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export { OwnProfileDetails };
