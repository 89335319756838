import React from 'react';
import '../../../styles/graphicComponents/loading/FunLoading.css';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';

const FunLoading = () => {
  return (
    <div className="fun-loading-container">
      <Header />
      <div className="fun-loading-animation">
        <div className="ball"></div>
        <p>Loading... Please wait!</p>
      </div>
      <Footer />
    </div>
  );
};

export { FunLoading };
