import React from 'react';
import { ClubsGrid } from './ClubsGrid';
import '../../../styles/graphicComponents/clubs/ClubsSection.css';

import { Header } from '../header/Header'
import { Footer } from '../footer/Footer'
import { ClubsProvider } from './ClubsContext';

const ClubsSection = () => {
    return (
        <div>
            < Header />

            <ClubsProvider>
                <div className="clubs-section-container">
                    <h1 id="clubs-section-title">Explore and Join the SEED Clubs!</h1>
                    <ClubsGrid />
                </div>
            </ClubsProvider>
            
            < Footer />
        </div>
    );
};

export { ClubsSection };
