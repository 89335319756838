import React, { useState } from 'react';
import { useDocuments } from '../documents/DocumentContext'; // Update the path as necessary
import '../../../styles/graphicComponents/documents/AddDocument.css';

function AddDocument({ onClose, setShowAddDocument, currentRoute }) { 
    const { addDocument } = useDocuments();
    const [newDocument, setNewDocument] = useState({
        name: '',
        classification: currentRoute,
        url: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDocument({ ...newDocument, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newDocument.name && newDocument.url) {
            addDocument(newDocument).then(() => {
                setShowAddDocument(false); // Close form on successful addition
            }).catch((error) => {
                console.error("Error adding document:", error);
                alert("Failed to add document. Please try again.");
            });
        } else {
            alert("Please fill in all fields.");
        }
    };

    return (
        <div className="add-document-overlay">
            <div className="add-document-container">
                <div className="close-button-container">
                    <button className="close-button" onClick={onClose}>X</button>
                </div>
                <h2>Add New Document</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={newDocument.name}
                        onChange={handleInputChange}
                        placeholder="Write a name for the document..."
                        required
                    />
                    <input
                        type="text"
                        name="url"
                        value={newDocument.url}
                        onChange={handleInputChange}
                        placeholder="Paste the document URL here..."
                        required
                    />
                    <div className="buttons">
                        <button className="cancel-button" type="button" onClick={onClose}>Cancel</button>
                        <button className="submit-button" type="submit">Add Document</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export { AddDocument };
