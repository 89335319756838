import React, { useEffect, useState } from 'react';
import { FunLoading } from '../Loading/FunLoading';
import '../../../styles/graphicComponents/mainMenu/MainMenu.css';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useAppData } from './AppDataProvider';

function MainMenu() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const { appData, loading } = useAppData(); // Access the centralized app data

  // State to track whether the data has been fully loaded
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const menuOptions = [
    { icon: 'svg/mainMenu/calendar.svg', text: 'Check the Calendar' },
    { icon: 'svg/mainMenu/explore-intranet.svg', text: 'Explore the Clubs!' },
    { icon: 'svg/mainMenu/recommendations.svg', text: 'Read Recommendations' },
    { icon: 'svg/mainMenu/meeting.svg', text: 'Start a Meeting' },
    { icon: 'svg/mainMenu/documents.svg', text: 'Research Documents' },
    { icon: 'svg/mainMenu/forums.svg', text: 'Have a Conversation' },
    { icon: 'svg/mainMenu/seedling.svg', text: 'Explore the Community' },
    { icon: 'svg/mainMenu/profile.svg', text: 'Update your profile' },
  ];

  console.log(appData);
  console.log("Is it loading?", loading)
  console.log("Is Data Loaded?", isDataLoaded)


  useEffect(() => {
    if ( 
      !loading
    ) {
      setIsDataLoaded(true);
    }
  }, [appData]); // Trigger when appData changes

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!isAuthenticated) {
        loginWithRedirect();
      }
    }, 1500); // 1.5 seconds delay

    return () => clearTimeout(timer);
  }, [isAuthenticated, loginWithRedirect]);

  const getFirstName = (fullName) => {
    return fullName ? fullName.split(' ')[0] : '';
  };

  // Logic Functions
  const handleOptionClick = (text) => {
    switch (text) {
      case 'Check the Calendar':
        navigate('/calendar');
        break;
      case 'Update your profile':
        navigate('/my-profile');
        break;
      case 'Read Recommendations':
        navigate('/recommendations');
        break;
      case 'Have a Conversation':
        navigate('/forums');
        break;
      case 'Start a Meeting':
        navigate('/meetings');
        break;
      case 'Explore the Clubs!':
        navigate('/clubs');
        break;
      case 'Research Documents':
        navigate('/documents');
        break;
      case 'Explore the Community':
        navigate('/community');
        break;
      default:
        break;
    }
  };

  const handleOptionKeyPress = (event, text) => {
    if (event.key === 'Enter') {
      handleOptionClick(text);
    }
  };

  const { currentUser } = appData;

  return (
    <div className="main-menu">
      {loading || !isDataLoaded ? (
        <FunLoading />
      ) : (
        <>
          <Header />
          <h1 id="main-menu-title">
            {`What would you like to do today${currentUser ? `, ${getFirstName(currentUser.name)}?` : '?'}`}
          </h1>
          <div className="options-grid">
            {menuOptions.map((option, index) => (
              <div
                key={index}
                className="menu-option"
                onClick={() => handleOptionClick(option.text)}
                onKeyDown={(event) => handleOptionKeyPress(event, option.text)}
                tabIndex={0}
              >
                <img src={option.icon} alt="Icon" />
                <p id="option-text">{option.text}</p>
              </div>
            ))}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export { MainMenu };
