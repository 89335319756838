import React, { useState } from 'react';
import '../../../styles/graphicComponents/calendar/NewTask.css';

const NewTask = ({ onSave, onClose }) => {
  const [descriptions, setDescriptions] = useState('');
  const [date, setDate] = useState('');

  const handleSave = (e) => {
    e.preventDefault();
    onSave({ descriptions, date }); // Make sure date format aligns with backend expectations
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="overlay">
      <div className="newtask-container">
        <div className='close-button-container'>
          <button onClick={handleClose}>X</button>
        </div>
        <h2>Add a New Task!</h2>
        <form className="newtask-form" onSubmit={handleSave}>
          <input
            type="text"
            placeholder="Task Description"
            value={descriptions}
            onChange={(e) => setDescriptions(e.target.value)}
            required
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <div id="submit-button-container">
            <button id="submit-button" type="submit">Save Task</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { NewTask };
