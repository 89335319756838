import React, { useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import "../../../styles/graphicComponents/header/Header.css";

const Header = () => {
  const { logout } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // Navigation
  const navigate = useNavigate();

  // Logic functions
  const handleMenuToggle = () => {
    isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
  };
  
  const handleMainMenuClick = () => {
    navigate('/intranet-welcome');
  };

  const handleCalendarSectionClick = () => {
    navigate('/calendar');
  };

  const handleClubsSectionClick = () => {
    navigate('/clubs');
  };

  const handleDocumentsSectionClick = () => {
    navigate('/documents');
  };


  return (
    <header id="permanent-header">
      <img id="seed-intranet-header-logo" src={`${process.env.PUBLIC_URL}/svg/SEED_Header_Logo.svg`} alt="SEED Intranet Logo" onClick={handleMainMenuClick} />
      
      <div id="header-go-to-menu-container-1" onClick={handleMainMenuClick}>
        <h2 id="go-to-menu-button">Main Menu</h2>
      </div>

      <div 
        id="header-sections-container" 
        onClick={handleMenuToggle}
        ref={menuRef}
      >
        <h2 id="intranet-sections-button">Quick Access</h2>
        <img id="header-expand-arrow" src={`${process.env.PUBLIC_URL}/svg/header-expand-arrow.svg`} alt="expand arrow"/>
        {isMenuOpen && (
          <div 
            id="deployable-list" 
          >
            <ul>
              <li onClick={handleCalendarSectionClick}>Calendar</li>
              <li onClick={handleClubsSectionClick}>Clubs</li>
              <li onClick={handleDocumentsSectionClick}>Documents</li>
            </ul>
          </div>
        )}
      </div>
      
      <div id="logout-section">
        <button id="logout-button" onClick={() => logout()}>Log Out</button>
      </div>
    </header>
  );
};

export { Header };
