// CommentSection.js
import React, { useState, useEffect } from 'react';
import '../../../styles/graphicComponents/forums/CommentSection.css';
import { useForums } from './ForumsContext';
import { FaPencilAlt } from 'react-icons/fa';
import { useComments } from './CommentsContext';
import EditingComment from './EditingComment'; // Import the EditingComment component

const CommentSection = ({ comments, addCommentToForum, currentUser, isLoading }) => {
  const [comment, setComment] = useState('');
  const { profiles } = useForums();
  const { deleteComment, updateComment } = useComments();
  const [isMatching, setIsMatching] = useState(true);
  const [matchedAuthors, setMatchedAuthors] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [showEditMenu, setShowEditMenu] = useState({}); // State for edit menus
  const [isEditing, setIsEditing] = useState({}); // State for editing modals

  // Match comment authors with their profiles
  useEffect(() => {
    const matchAuthors = () => {
      const authorData = {};
      comments.forEach((comment) => {
        const profile = profiles.find(profile => profile.id === comment.authorID);
        if (profile) {
          authorData[comment.authorID] = profile;
        }
      });
      setMatchedAuthors(authorData);
      setIsMatching(false);
    };

    if (!isLoading && comments.length > 0) {
      matchAuthors();
    } else {
      setIsMatching(false);
    }
  }, [comments, profiles, isLoading]);

  // Handle adding a new comment
  const handleAddComment = () => {
    if (!comment.trim()) return;

    const creationDate = new Date().toISOString();
    const newComment = {
      content: comment,
      creationDate: creationDate,
    };
    addCommentToForum(newComment);
    setComment('');
  };

  const handleDeleteClick = (id) => {
    setConfirmDelete(id);
    setShowEditMenu(prev => ({ ...prev, [id]: false }));
  };

  const confirmDeleteComment = async (id) => {
    await deleteComment(id);
    setConfirmDelete(null);
  };

  // Sort comments by creationDate (most recent first)
  const sortedComments = comments.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

  return (
    <div className="comment-section">
      <h2 className='comments-header'>Comments</h2>
      <div className={`comments-list ${Object.values(isEditing).some(val => val) ? '' : ''}`}>
        {isMatching ? (
          <div className="fun-loading-animation">
            <div className="ball"></div>
            <p>Loading... Please wait!</p>
          </div>
        ) : (
          sortedComments.map((comment, index) => {
            const author = matchedAuthors[comment.authorID];

            const usersWhoLikedList = comment.usersWhoLiked || [];
            const isLiked = usersWhoLikedList.includes(currentUser.id);

            // Handle like per comment
            const handleLike = () => {
              if (isLiked) {
                const updatedUsersWhoLiked = usersWhoLikedList.filter(id => id !== currentUser.id);
                updateComment(comment.id, { ...comment, usersWhoLiked: updatedUsersWhoLiked });
              } else {
                updateComment(comment.id, { ...comment, usersWhoLiked: [...usersWhoLikedList, currentUser.id] });
              }
            };

            const handleEditClick = (id) => {
              setIsEditing(prev => ({ ...prev, [id]: true }));
              setShowEditMenu(prev => ({ ...prev, [id]: false }));
            };

            return (
              <div key={index} className="comment-bubble-container">
                <div className="comment-bubble">
                  <div className="comment-author">
                    {author && (
                      <>
                        <img src={author.picture} alt={author.name} className="comment-author-picture" />
                        <span>{author.name}</span>
                      </>
                    )}
                  </div>
                  <div>
                    <p id="creation-date-comment">{new Date(comment.creationDate).toLocaleDateString('en-US', {
                      year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
                    })}</p>
                  </div>
                  <p className='comment-text'>{comment.content}</p>

                  <div id="comment-actions">
                    {/* Like button and count */}
                    <img
                      id='heart-svg'
                      src={`${process.env.PUBLIC_URL}/svg/heart-${isLiked ? 'filled' : 'unfilled'}.svg`}
                      alt={isLiked ? 'heart filled' : 'heart unfilled'}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike();
                      }}
                    />
                    <p id='like-count'>{usersWhoLikedList.length}</p>

                    {/* Show edit/delete options only if the current user is the author */}
                    {comment.authorID === currentUser.id && (
                      <div id="edit-delete-container">
                        <FaPencilAlt
                          id="pencil-icon"
                          onClick={() => setShowEditMenu(prev => ({ ...prev, [comment.id]: !prev[comment.id] }))}
                        />
                        <p id="edit-comment-text">Edit or Delete</p>
                        {showEditMenu[comment.id] && (
                          <div className="edit-menu-comment">
                            <button onClick={() => handleEditClick(comment.id)}>Edit</button>
                            <button onClick={() => handleDeleteClick(comment.id)}>Delete</button>
                            <button onClick={() => setShowEditMenu(prev => ({ ...prev, [comment.id]: false }))}>Cancel</button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {confirmDelete === comment.id && (
                  <div className="confirm-delete-comment">
                    <p>Are you sure you want to delete this comment?</p>
                    <button onClick={() => confirmDeleteComment(comment.id)}>Yes</button>
                    <button onClick={() => setConfirmDelete(null)}>No</button>
                  </div>
                )}
                {isEditing[comment.id] && (
                  <EditingComment
                    comment={comment}
                    updateComment={updateComment}
                    onClose={() => setIsEditing(prev => ({ ...prev, [comment.id]: false }))}
                  />
                )}
              </div>
            );
          })
        )}
      </div>
      <div className="add-comment">
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment"
        />
        <button onClick={handleAddComment} className='add-comment-button'>Add Comment</button>
      </div>
    </div>
  );
};

export { CommentSection };
