// EditingForum.js
import React, { useState } from 'react';
import '../../../styles/graphicComponents/forums/EditingForum.css';

const EditingForum = ({ forum, updateForum, onClose }) => {
  const [title, setTitle] = useState(forum.title);
  const [content, setContent] = useState(forum.content);

  const handleUpdate = () => {
    const updatedForum = {
      ...forum,
      title,
      content,
    };
    updateForum(forum.id, updatedForum);
    onClose();
  };

  return (
    <div className="editing-forum-modal">
      <div className="editing-forum-content">
        <h2>Edit Your Forum Post</h2>
        <input
          id='editing-forum-title'
          type="text"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Title"
        />
        <textarea
          id='editing-forum-textarea'
          value={content}
          onChange={e => setContent(e.target.value)}
          placeholder="Content"
        />
        <div className="editing-forum-buttons">
          <button id='cancel-forum-edit-button' onClick={onClose}>Cancel</button>
          <button id='save-forum-edit-button' onClick={handleUpdate}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default EditingForum;
