import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NotFound } from '../graphicComponents/NotFound/NotFound';
import { LandingPage } from '../graphicComponents/LandingPage/LandingPage';
import { MainMenu } from '../graphicComponents/mainMenu/MainMenu';
import { MyCalendarSection } from '../graphicComponents/calendar/MyCalendarSection';
import { ProfileSection } from '../graphicComponents/profile/ProfileSection';
import { RecommendationsSection } from '../graphicComponents/recommendations/RecommendationsSection';
import { ForumsSection } from '../graphicComponents/forums/ForumsSection';
import { MeetingsSection } from '../graphicComponents/meetings/MeetingsSection';
import { ClubsSection } from '../graphicComponents/clubs/ClubsSection';

import { Etiquette } from '../graphicComponents/clubs/concrete-clubs/Etiquette';
import { FashionFood } from '../graphicComponents/clubs/concrete-clubs/FashionFood';
import { Geography } from '../graphicComponents/clubs/concrete-clubs/Geography';
import { Innovation } from '../graphicComponents/clubs/concrete-clubs/Innovation';
import { LanguageExchange } from '../graphicComponents/clubs/concrete-clubs/LanguageExchange';
import { MoviesCulture } from '../graphicComponents/clubs/concrete-clubs/MoviesCulture';
import { Research } from '../graphicComponents/clubs/concrete-clubs/Research';
import { Professional } from '../graphicComponents/clubs/concrete-clubs/Professional';

import { DocumentsSection } from '../graphicComponents/documents/DocumentsSection';
import { DocumentCategoryView } from '../graphicComponents/documents/DocumentCategoryView';
import { CommunitySection } from '../graphicComponents/community/CommunitySection';
import { ParticipantList } from '../graphicComponents/community/ParticipantList';
import { ParticipantProfile } from '../graphicComponents/profile/ParticipantProfile';

import { DocumentGrid } from '../graphicComponents/documents/DocumentGrid';
import { DocumentsProvider } from '../graphicComponents/documents/DocumentContext';
import { MeetingsProvider } from '../graphicComponents/meetings/MeetingsContext';

import { PrivateRoute } from './PrivateRoute'; // Import the PrivateRoute component
import { ForumsProvider } from '../graphicComponents/forums/ForumsContext';

const AppRouter = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/intranet-welcome" element= {<MainMenu />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/calendar" element={<PrivateRoute element={<MyCalendarSection />} />} />
        <Route path="/my-profile" element={<PrivateRoute element={<ProfileSection />} />} />
        <Route path="/recommendations" element={<PrivateRoute element={<RecommendationsSection />} />} />
        <Route path="/forums/*" element={<PrivateRoute element={<ForumsProvider><ForumsSection /></ForumsProvider>} />} />
        <Route path="/meetings" element={<PrivateRoute element={<MeetingsProvider><MeetingsSection /></MeetingsProvider>} />} />
        <Route path="/clubs" element={<PrivateRoute element={<ClubsSection />} />} />
        <Route path="/clubs/etiquette" element={<PrivateRoute element={<Etiquette />} />} />
        <Route path="/clubs/fashion-and-food" element={<PrivateRoute element={<FashionFood />} />} />
        <Route path="/clubs/geography" element={<PrivateRoute element={<Geography />} />} />
        <Route path="/clubs/innovation" element={<PrivateRoute element={<Innovation />} />} />
        <Route path="/clubs/language-exchange" element={<PrivateRoute element={<LanguageExchange />} />} />
        <Route path="/clubs/movies-and-culture" element={<PrivateRoute element={<MoviesCulture />} />} />
        <Route path="/clubs/research-methods" element={<PrivateRoute element={<Research />} />} />
        <Route path="/clubs/professional-culture" element={<PrivateRoute element={<Professional />} />} />
        <Route path="/documents" element={<PrivateRoute element={<DocumentsSection />} />} />
        <Route path="/documents/:category" element={<PrivateRoute element={<DocumentsProvider><DocumentCategoryView /></DocumentsProvider>} />} />
        <Route path="/documents/:clubName" element={<PrivateRoute element={<DocumentsProvider><DocumentGrid /></DocumentsProvider>} />} />
        <Route path="/meetings/:clubName" element={<PrivateRoute element={<MeetingsProvider><MeetingsSection /></MeetingsProvider>} />} />
        <Route path="/community" element={<PrivateRoute element={<CommunitySection />} />} />
        <Route path="/community/:group" element={<PrivateRoute element={<ParticipantList />} />} />
        <Route path="/community/profiles/my-profile" element={<PrivateRoute element={<ProfileSection />} />} />
        <Route path="/community/profiles/:id" element={<PrivateRoute element={<ParticipantProfile />} />} />
      </Routes>
    </Router>
  );
};

export { AppRouter };
