import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAppData } from '../mainMenu/AppDataProvider'; // Import the general context

const ForumContext = createContext();

// Custom hook for using context
export const useForums = () => {
  const context = useContext(ForumContext);
  if (context === undefined) {
    throw new Error('useForums must be used within a ForumsProvider');
  }
  return context;
};

export const ForumsProvider = ({ children }) => {
  const { appData, setAppData } = useAppData(); // Access the general context
  const [isLoading, setIsLoading] = useState(true);

  const baseURL = 'https://seed-alumni.org/api/v1'; // Production
  //const baseURL = 'http://localhost:3001/api/v1'; // Development URL

  const fetchForums = async () => {
    try {
      const response = await fetch(`${baseURL}/forums`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAppData(prevData => ({
        ...prevData,
        forums: data,
      }));
      return data;
    } catch (error) {
      console.error("Failed to fetch forums:", error);
      return [];
    }
  };

  const addForum = async (forumDto) => {
    try {
      const response = await fetch(`${baseURL}/forums`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(forumDto),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newForum = await response.json();
      const updatedForums = [...appData.forums, newForum]; // Add new forum to the existing forums
      setAppData(prevData => ({
        ...prevData,
        forums: updatedForums,
      }));
    } catch (error) {
      console.error("Failed to add forum:", error);
    }
  };

  const updateForum = async (id, forumDto) => {
    try {
      const response = await fetch(`${baseURL}/forums/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(forumDto),
      });
      if (response.ok) {
        const updatedForums = await fetchForums(); // Refetch all forums after update
        setAppData(prevData => ({
          ...prevData,
          forums: updatedForums,
        }));
      }
    } catch (error) {
      console.error("Failed to update forum:", error);
    }
  };

  const deleteForum = async (id) => {
    try {
      const response = await fetch(`${baseURL}/forums/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const updatedForums = appData.forums.filter(forum => forum.id !== id); // Remove the deleted forum from state
        setAppData(prevData => ({
          ...prevData,
          forums: updatedForums,
        }));
      }
    } catch (error) {
      console.error("Failed to delete forum:", error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await fetchForums(); // Fetch forums and update general context
      setIsLoading(false); // Set loading to false after data is loaded
    };
    loadData();
  }, []);

  return (
    <ForumContext.Provider value={{ 
      forums: appData.forums, 
      addForum, 
      updateForum, 
      deleteForum, 
      fetchForums, 
      profiles: appData.profiles, 
      isLoading 
    }}>
      {children}
    </ForumContext.Provider>
  );
};
