import React, { useState, useEffect } from 'react';
import '../../../../styles/graphicComponents/clubs/concrete-clubs/EditingClubs.css';
import { useAuth0 } from '@auth0/auth0-react';

function EditingClub({ club, onSave, onClose }) {
    const [name, setName] = useState(club.name);
    const [bannerImage, setBannerImage] = useState(club.bannerImage);
    const [mainDescription, setMainDescription] = useState(club.mainDescription);
    const { isAuthenticated } = useAuth0();

    const handleSave = () => {
        const updatedClub = {
            id: club.id,
            name,
            bannerImage,
            mainDescription,
        };
        onSave(updatedClub);
    };

    useEffect(() => {
        // Center the form and disable background scroll
        document.body.style.overflow = 'hidden';

        return () => {
            // Re-enable scroll when the component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div id="blurred-background"></div>
            <div id="editing-club-container">
                <button id="close-button" onClick={onClose}>X</button>
                <h2>Edit Your Club Details!</h2>
                <input 
                    type="text" 
                    placeholder="Club Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                />
                <input 
                    type="text" 
                    placeholder="Banner Image URL" 
                    value={bannerImage} 
                    onChange={(e) => setBannerImage(e.target.value)} 
                />
                <textarea 
                    placeholder="Main Club Description" 
                    value={mainDescription} 
                    onChange={(e) => setMainDescription(e.target.value)} 
                />
                <button id='save-button' onClick={handleSave}>Save</button>
            </div>
        </>
    );
}

export { EditingClub };
