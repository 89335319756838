import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useAppData } from '../mainMenu/AppDataProvider';
import '../../../styles/graphicComponents/community/ParticipantList.css';
import { IoArrowBack } from 'react-icons/io5';
import { FunLoading } from '../Loading/FunLoading';

function ParticipantList() {
    const { group } = useParams();
    const navigate = useNavigate();
    const { appData, loading } = useAppData();
    const [groupedParticipants, setGroupedParticipants] = useState({});
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // Show loading animation for 2 seconds
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 500);

        // Cleanup the timer if the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const filterParticipants = () => {
            let filteredParticipants = [];
            const groupLowerCase = group.toLowerCase();

            switch (groupLowerCase) {
                case 'directors':
                    filteredParticipants = appData.profiles.filter(profile => profile.role === 'director');
                    break;
                case 'coordinators':
                    filteredParticipants = appData.profiles.filter(profile => profile.role === 'coordinator');
                    break;
                case 'professors':
                    filteredParticipants = appData.profiles.filter(profile => profile.role === 'professor');
                    break;
                case 'participants':
                    filteredParticipants = appData.profiles.filter(profile => profile.role === 'participant');
                    break;
                default:
                    filteredParticipants = appData.profiles; // No filtering for unrecognized group
                    break;
            }

            const byYear = filteredParticipants.reduce((acc, cur) => {
                if (cur.participationYear) {
                    acc[cur.participationYear] = [...(acc[cur.participationYear] || []), cur];
                }
                return acc;
            }, {});
            setGroupedParticipants(byYear);
        };

        if (!loading && appData.profiles.length > 0) { // Filter only when profiles are fully loaded
            filterParticipants();
        }
    }, [group, appData.profiles, loading]);

    const handleParticipantClick = (participant) => {
        navigate(`/community/profiles/${participant.id}`); // Ensure this redirects to the correct route
    };

    const handleGoBack = () => {
        navigate('/community');
    };

    const handleImageError = (e, attempt = 1) => {
        if (attempt < 1) {
            // Retry loading the image up to 3 times
            setTimeout(() => {
                e.target.src = e.target.src + `?retry=${attempt}`; // Add a query parameter to force reload
                e.target.onerror = (err) => handleImageError(err, attempt + 1); // Increase attempt count
            }, 1000); // Retry after 1 second
        } else {
            // After 3 attempts, do not try further
            console.error(`Failed to load image after ${attempt} attempts:`, e.target.src);
        }
    };

    if (loading || !appData.profiles.length) {
        return (
            <div className="fun-loading-animation">
                <div className="ball"></div>
                    <p>Loading... Please wait!</p>
            </div>
        );
    }

    if (!showContent) {
        return (
            <FunLoading/>
        );
    }

    return (
        <div>
            <Header />

            <div className="participant-list-container">

                <button id="go-back-button" onClick={handleGoBack}>
                    <IoArrowBack size={34} />
                </button>

                <h1 className="group-header">{group.toUpperCase()}</h1>
                {Object.entries(groupedParticipants).length === 0 ? (
                    <p id="no-participants-found">No participants found for this group.</p>
                ) : (
                    Object.entries(groupedParticipants).map(([year, participants]) => (
                        <div key={year} className="year-section">
                            <h2 className="year-header">{year}</h2>
                            <div className="participants-grid">
                                {participants.map(participant => (
                                    <div key={participant.id} className="participant-card" onClick={() => handleParticipantClick(participant)}>
                                        <div className="participant-image-container">
                                            <img
                                                src={participant.picture} // Load the actual image
                                                alt={participant.name}
                                                id="profile-picture"
                                                onLoad={(e) => {
                                                    e.target.style.opacity = 1;
                                                    e.target.style.filter = 'blur(0)';
                                                }}
                                                onError={(e) => handleImageError(e)} // Retry loading on error
                                                loading="lazy"
                                                style={{ opacity: 0, transition: 'opacity 0.3s ease-in', zIndex: 0 }}
                                            />
                                        </div>
                                        <p className="participant-name">{participant.name}</p>
                                        <p className="small-text">{participant.major}</p>
                                        <p className="small-text">{participant.university}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Footer />
        </div>
    );
}

export { ParticipantList };
