import React, { createContext, useState, useContext, useEffect } from 'react';

const ClubsContext = createContext();

export const useClubs = () => useContext(ClubsContext);

export const ClubsProvider = ({ children }) => {
  const [clubs, setClubs] = useState([]);

  // Backend URL
  const baseURL = 'https://seed-alumni.org/api/v1';

  // For development
  //const baseURL = 'http://localhost:3001/api/v1'; // Development URL

  const fetchClubs = async () => {
    try {
      const response = await fetch(`${baseURL}/clubs`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setClubs(data);
    } catch (error) {
      console.error("Failed to fetch clubs:", error);
    }
  };

  const addClub = async (clubDto) => {
    try {
      const response = await fetch(`${baseURL}/clubs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clubDto),
      });
      if (response.ok) {
        fetchClubs();
      }
      return response;
    } catch (error) {
      console.error("Failed to add club:", error);
    }
  };

  const updateClub = async (id, clubDto) => {
    try {
      const response = await fetch(`${baseURL}/clubs/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clubDto),
      });
      if (response.ok) {
        fetchClubs();
      }
      return response;
    } catch (error) {
      console.error("Failed to update club:", error);
    }
  };

  const deleteClub = async (id) => {
    try {
      const response = await fetch(`${baseURL}/clubs/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchClubs();
      }
      return response;
    } catch (error) {
      console.error("Failed to delete club:", error);
    }
  };

  useEffect(() => {
    fetchClubs();
  }, []);

  return (
    <ClubsContext.Provider value={{ clubs, addClub, updateClub, deleteClub, fetchClubs }}>
      {children}
    </ClubsContext.Provider>
  );
};
