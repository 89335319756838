import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppData } from '../mainMenu/AppDataProvider'; // Import the general context

const RecommendationsContext = createContext();

export const useRecommendations = () => useContext(RecommendationsContext);

export const RecommendationsProvider = ({ children }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const { user, isAuthenticated } = useAuth0();
  const { setAppData } = useAppData(); // Access the general context

  // Backend URL
  //const baseURL = 'http://localhost:3001/api/v1'; // Development
  const baseURL = 'https://seed-alumni.org/api/v1'; // Production

  // Fetch recommendations
  const fetchRecommendations = useCallback(async () => {
    try {
      const response = await fetch(`${baseURL}/recommendations`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRecommendations(data);
      setAppData(prevData => ({
        ...prevData,
        recommendations: data,
      }));
    } catch (error) {
      console.error("Failed to fetch recommendations:", error);
    }
  }, [setAppData]);

  // Fetch current user
  const fetchCurrentUser = async (email) => {
    try {
        const response = await fetch(`${baseURL}/users/login/${email}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const userData = await response.json();
        setCurrentUser(userData); // Store the fetched user data
        return userData;
    } catch (error) {
        console.error("Failed to fetch current user:", error);
        return null;
    }
  };

  // Add recommendation
  const addRecommendation = async (recommendationDto) => {
    try {
      const response = await fetch(`${baseURL}/recommendations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(recommendationDto),
      });
      if (response.ok) {
        await fetchRecommendations(); // Re-fetch after adding a recommendation
      }
      return response;
    } catch (error) {
      console.error("Failed to add recommendation:", error);
    }
  };

  // Update recommendation
  const updateRecommendation = async (id, recommendationDto) => {
    try {
      const response = await fetch(`${baseURL}/recommendations/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(recommendationDto),
      });
      if (response.ok) {
        await fetchRecommendations(); // Re-fetch after updating a recommendation
      }
      return response;
    } catch (error) {
      console.error("Failed to update recommendation:", error);
    }
  };

  // Delete recommendation
  const deleteRecommendation = async (id) => {
    try {
      const response = await fetch(`${baseURL}/recommendations/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        await fetchRecommendations(); // Re-fetch after deleting a recommendation
      }
      return response;
    } catch (error) {
      console.error("Failed to delete recommendation:", error);
    }
  };

  useEffect(() => {
    fetchRecommendations();
    if (isAuthenticated && user && user.email) {
      fetchCurrentUser(user.email); // Fetch current user data when authenticated and email is available
    }
  }, [isAuthenticated, user, fetchRecommendations]);

  return (
    <RecommendationsContext.Provider value={{
      recommendations,
      addRecommendation,
      updateRecommendation,
      deleteRecommendation,
      fetchRecommendations,
      fetchCurrentUser,
      currentUser
    }}>
      {children}
    </RecommendationsContext.Provider>
  );
};
