import React, { useState } from 'react';
import '../../../styles/graphicComponents/LandingPage/LandingPage.css';
import YouTube from 'react-youtube';

import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from './LoginButton';

function LandingPage() {

  // State variables
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth0();

  // Const variables
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: '2297dPpo9tk',
      controls: 0,  // Hides YouTube controls
      disablekb: 1,  // Disables keyboard controls
      modestbranding: 1,  // Hides YouTube logo
      fs: 0,  // Hides fullscreen button
      iv_load_policy: 3,  // Hides video annotations
      autohide: 1  // Hides video controls when playing
    },
  };


  // Hooks
  const onReady = (event) => {
    event.target.mute();
    setLoading(false);
  };

  // Logic functions

  const scrollToAboutIntranet = () => {
    const aboutIntranetHeader = document.getElementById('about-intranet-header');
    aboutIntranetHeader.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="external-container-landing-page">

      <div className={`landing-page ${loading ? 'loading' : ''}`} >
        <header className="header">
          <div>
              <img id="seed_logo_img" src="svg/seed_logo.svg" alt="SEED logo" />
          </div>
          <h1 className="title">INTRANET</h1>
          
          <div className="seed-main-buttons-container">
            < LoginButton />
            <button className="seed-button" onClick={scrollToAboutIntranet}>What is the Intranet?</button>
          </div>  

          <div>
            <YouTube
              videoId="2297dPpo9tk"
              opts={opts}
              className="video-background"
              onReady={onReady}
            />

            <div>
              <p id="by-text">By</p>
              <img id="gorom_logo_img" src="svg/gorom_logo.svg" alt="GOROM Logo" />
            </div>
          </div>
        </header>

        <div className='intranet-about-container'> 

          <div className='intranet-header-container'>
            <h2 id="learn-intranet-header">Learn More about the SEED Alumni & Intranet</h2>

            <div className='arrow-container'>
              <img id="arrow" src="svg/down_arrow.svg" alt="Down arrow" onClick={scrollToAboutIntranet} />
            </div>
          </div>

            <div id="intranet-info-container">

                <h2 id="about-intranet-header">The SEED Program Alumni</h2>
                <p id="about-intranet-text">
                The SEED program student network (SEED Program Alumni) is an initiative
                of former participants of the 2023 edition of the SEED program that seeks 
                to establish a space for continuous interaction of all past and current 
                participants of the GOROM Association social entrepreneurship program.
                </p>

                <p id="last-about-text">
                The great motivation of the Intranet is to enable a digital space to keep
                alive the bonds of friendship that have been formed between the program 
                participants. Through activities, forums, a space to share experiences 
                and the option of organizing synchronous meetings, each member of the 
                SEED family will be able to enhance their personal and intrapersonal 
                development with the aim of complementing their professional path.
                </p>

            </div>

        </div>

        <div className="footer-main-container">
          <footer className="landing-footer">
            <p id="footer-gorom-url-landing">More information about GOROM Association in: <a id='footer-gorom-url' target="_blank" rel="noreferrer" href='https://gorom.org/en/about-us/'>gorom.org/en</a></p>
            <p id="footer-contact-info-landing">Having technical issues? Contact <strong>fai.ahertz@seed-alumni.org</strong></p>
          </footer>
        </div>

      </div>

      <div className="img-1-container">
        <img id="landing-background-img" src="https://seed-alumni.org/landing-page/landing-page-1.jpg" alt="Background-img" />
      </div>

      <div className="img-2-container">
      <img id="landing-background-img-2" src="https://seed-alumni.org/landing-page/landing-page-2.jpg" alt="Background-img" />
      </div>

    </div>
    
  );
}

export { LandingPage };