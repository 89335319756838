import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from '@auth0/auth0-react';
import { TokenLogger } from './components/tokens/TokenLogger';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-2v1olbks444fomib.us.auth0.com"
      clientId="IkqG2UUZ9kKGE4ITPN7oNrUT5k1knBeJ"
      audience={`https://dev-2v1olbks444fomib.us.auth0.com/api/v2/`}
      scope="read:current_user"
      authorizationParams={{
        redirect_uri: "https://seed-alumni.org/intranet-welcome"
        //redirect_uri: "http://localhost:3000/intranet-welcome"
      }}
    >
      <App />

      <TokenLogger />
      
    </Auth0Provider> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
