import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../../../styles/graphicComponents/forums/ForumsList.css';
import { AddForumEntry } from './AddForumEntry';
import { useForums } from './ForumsContext';

const ForumsList = () => {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [showAddForm, setShowAddForm] = useState(false);
  const formRef = useRef(null);

  const { forums, addForum } = useForums(); // Get forums and addForum method from context

  const handleEntryClick = (id) => {
    navigate(`/forums/${id}`);
  };

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowAddForm(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSaveForum = async (newForum) => {
    await addForum(newForum); // Add the forum and update the state
    setShowAddForm(false); // Close the form after saving
  };

  const sortedForums = forums.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

  return (
    <div className="forums-list-container">
      <h1>Conversations & Experiences</h1>
      <ul>
        {sortedForums && sortedForums.length > 0 ? (
          sortedForums.map((forum) => (
            <li key={forum.id} onClick={() => handleEntryClick(forum.id)}>
              <h2>{forum.title}</h2>
              <p>by {forum.author}</p>
            </li>
          ))
        ) : (
          <p>No conversations available</p>
        )}
      </ul>

      <button className="add-forum-button" onClick={() => setShowAddForm(true)}>+</button>
      {showAddForm && (
        <div ref={formRef}>
          <AddForumEntry
            onSave={handleSaveForum} // Use the handleSaveForum function
            onClose={() => setShowAddForm(false)}
          />
        </div>
      )}
    </div>
  );
};

export { ForumsList };
