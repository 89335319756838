import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ProfilesContext = createContext();

export const useProfiles = () => useContext(ProfilesContext);

export const ProfilesProvider = ({ children }) => {
    const { user, isAuthenticated } = useAuth0();
    const [profile, setProfile] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);

    const baseURL = 'https://seed-alumni.org/api/v1'; // Production
    //const baseURL = 'http://localhost:3001/api/v1'; // Development URL

    const fetchProfileByGoromLogin = async (goromLogin) => {
        if (!goromLogin) {
            console.error("goromLogin is undefined or null");
            return;
        }

        try {
            const response = await fetch(`${baseURL}/users/login/${goromLogin}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setProfile(data);
        } catch (error) {
            console.error("Failed to fetch profile:", error);
        }
    };

    const fetchAllProfiles = async (retryCount = 0) => {
        try {
            const response = await fetch(`${baseURL}/users`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setProfiles(data);
            setLoading(false);
        } catch (error) {
            console.error(`Failed to fetch profiles: ${error.message}`);
            
            // Retry with exponential backoff
            if (retryCount < 5) { // Maximum 5 retries
                const delay = Math.pow(2, retryCount) * 500; // Exponential backoff
                console.log(`Retrying in ${delay / 1000} seconds...`);
                setTimeout(() => fetchAllProfiles(retryCount + 1), delay);
            } else {
                console.error("Max retries reached. Failed to fetch profiles.");
                setProfiles([]);
                setLoading(false); // Stop loading and maybe set an error state
            }
        }
    };

    useEffect(() => {
        if (isAuthenticated && user) {
            const goromLogin = user.email;
            if (goromLogin) {
                fetchProfileByGoromLogin(goromLogin);
            } else {
                console.error("User email is undefined or null");
            }
            fetchAllProfiles();
        } else {
            setLoading(false); // Set loading to false if not authenticated
        }
    }, [isAuthenticated, user]);

    const updateProfile = async (profileDto) => {
        try {
            const response = await fetch(`${baseURL}/users/${profileDto.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profileDto),
            });
            if (response.ok) {
                const updatedProfile = await response.json();
                setProfile(updatedProfile);

                // Update the profile in the profiles list
                setProfiles((prevProfiles) =>
                    prevProfiles.map((p) => (p.id === updatedProfile.id ? updatedProfile : p))
                );
            }
            return response;
        } catch (error) {
            console.error("Failed to update profile:", error);
        }
    };

    return (
        <ProfilesContext.Provider value={{ profile, profiles, loading, updateProfile, fetchProfileByGoromLogin, fetchAllProfiles }}>
            {children}
        </ProfilesContext.Provider>
    );
};
