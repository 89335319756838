import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TokenLogger = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const logTokens = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          console.log('Access Token:', accessToken);
          console.log('ID Token:', user);
        } catch (error) {
          console.error('Error getting tokens', error);
        }
      } else {
        console.log('User is not authenticated');
      }
    };

    logTokens();
  }, [isAuthenticated, getAccessTokenSilently, user]);

  return null;
};

export { TokenLogger };
