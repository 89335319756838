import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ForumsList } from './ForumsList';
import { ForumEntry } from './ForumEntry';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useAuth0 } from '@auth0/auth0-react';
import { useForums } from './ForumsContext';
import { FunLoading } from '../Loading/FunLoading';
import { CommentsProvider } from './CommentsContext';
import { useAppData } from '../mainMenu/AppDataProvider';

const ForumsSection = () => {
  const { user, isAuthenticated } = useAuth0();
  const { appData, setAppData, loading } = useAppData(); // Access the general context
  const { forums, fetchForums } = useForums();

  useEffect(() => {
    const initializeData = async () => {
      if (isAuthenticated) {

        if (!appData.forums.length) {
          await fetchForums(); // Ensure forums are fetched and stored in the general context
        }
      }
    };

    initializeData();
  }, [isAuthenticated, user.email, appData, fetchForums, setAppData]);

  if (loading || !appData.currentUser) {
    return <div><FunLoading /></div>;
  }

  return (
    <div className="forums-section">
      <Header />
      <Routes>
        <Route 
          path="/" 
          element={<ForumsList forums={forums} addForum={fetchForums} />} 
        />
        <Route 
          path="/:id" 
          element={
            <CommentsProvider>
              <ForumEntry forums={forums} currentUser={appData.currentUser} />
            </CommentsProvider>
          } 
        />
      </Routes>
      <Footer />
    </div>
  );
};

export { ForumsSection };
