import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DocumentGrid } from './DocumentGrid';
import '../../../styles/graphicComponents/documents/DocumentCategoryView.css';
import { IoArrowBack } from 'react-icons/io5';

import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';

function DocumentCategoryView() {
    const { category } = useParams();
    const [documents, setDocuments] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/mock-data/documents/documentsData.json`)
            .then(response => response.json())
            .then(data => {
                // Assuming data is an object where keys are categories
                setDocuments(data[category] || []);
            })
            .catch(error => console.error('Error loading the documents:', error));
    }, [category]);

    const addDocument = (newDocument) => {
        const updatedDocuments = [...documents, {...newDocument, id: documents.length + 1}];
        setDocuments(updatedDocuments);  // Update documents array with new document
    };

    const handleGoBack = () => {
        navigate('/documents');
      };

    return (
        <div>
            <Header />
            <div>
                <button id="go-back-button" onClick={handleGoBack}>
                    <IoArrowBack size={34} />
                </button>
                <h1 className='documents-grid-header'>{category.replace(/-/g, ' ')} Documents</h1>
                <DocumentGrid documents={documents} addDocument={addDocument} />
            </div>
            <Footer />
        </div>
    );
}

export { DocumentCategoryView };
