import React from 'react';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { Groups } from './Groups';

import '../../../styles/graphicComponents/community/CommunitySection.css';
import { ProfilesProvider } from '../profile/ProfilesContext';

function CommunitySection() {
    return (
        <div>
            <Header />
                <h1 className="community-header">Meet the Community of the SEED Program!</h1>
                <Groups />
            <Footer />
        </div>
    );
}

export { CommunitySection };
