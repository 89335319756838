import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FutureMeetings } from './FutureMeetings';
import { MeetingsOptions } from './MeetingsOptions';
import '../../../styles/graphicComponents/meetings/MeetingsSection.css';

import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useAppData } from '../mainMenu/AppDataProvider'; // Import useAppData
import { useMeetings } from './MeetingsContext'; // Import useMeetings

const MeetingsSection = () => {
  const { appData, setAppData } = useAppData(); // Access appData and setAppData from context
  const { fetchMeetings, addMeeting, updateMeeting, deleteMeeting } = useMeetings(); // Access meeting methods
  const location = useLocation();
  const currentRoute = location.pathname; // Get current route (classification)

  useEffect(() => {
    // Fetch meetings for the current route when the component loads or the route changes
    const fetchMeetingsByRoute = async () => {
      try {
        const meetings = await fetchMeetings(); // Fetch all meetings

        // Ensure meetings for the current route exist and are initialized to an empty array if undefined
        setAppData((prevData) => ({
          ...prevData,
          meetings: {
            ...prevData.meetings,
            [currentRoute]: Array.isArray(meetings)
              ? meetings.filter((meeting) => meeting.classification === currentRoute)
              : [], // Initialize to an empty array if the fetched meetings are not an array
          },
        }));
      } catch (error) {
        console.error("Failed to fetch meetings for the current route:", error);
      }
    };

    // Ensure that meetings for the current route exist and are initialized as an empty array if undefined
    if (!appData.meetings[currentRoute]) {
      fetchMeetingsByRoute();
    }
  }, [currentRoute, fetchMeetings, appData.meetings, setAppData]);

  const handleAddMeeting = async (newMeeting) => {
    try {
      await addMeeting(newMeeting); // Add meeting via MeetingsContext
      // Re-fetch and update meetings in AppData after adding a meeting
      const meetings = await fetchMeetings();
      setAppData((prevData) => ({
        ...prevData,
        meetings: {
          ...prevData.meetings,
          [currentRoute]: Array.isArray(meetings)
            ? meetings.filter((meeting) => meeting.classification === currentRoute)
            : [], // Initialize to an empty array if the fetched meetings are not an array
        },
      }));
    } catch (error) {
      console.error("Failed to add meeting:", error);
    }
  };

  const handleUpdateMeeting = async (id, updatedMeeting) => {
    try {
      await updateMeeting(id, updatedMeeting); // Update meeting
      // Re-fetch and update meetings after the update
      const meetings = await fetchMeetings();
      setAppData((prevData) => ({
        ...prevData,
        meetings: {
          ...prevData.meetings,
          [currentRoute]: Array.isArray(meetings)
            ? meetings.filter((meeting) => meeting.classification === currentRoute)
            : [], // Initialize to an empty array if the fetched meetings are not an array
        },
      }));
    } catch (error) {
      console.error("Failed to update meeting:", error);
    }
  };

  const handleDeleteMeeting = async (id) => {
    try {
      await deleteMeeting(id); // Delete the meeting
      // Re-fetch and update meetings after the deletion
      const meetings = await fetchMeetings();
      setAppData((prevData) => ({
        ...prevData,
        meetings: {
          ...prevData.meetings,
          [currentRoute]: Array.isArray(meetings)
            ? meetings.filter((meeting) => meeting.classification === currentRoute)
            : [], // Initialize to an empty array if the fetched meetings are not an array
        },
      }));
    } catch (error) {
      console.error("Failed to delete meeting:", error);
    }
  };

  return (
    <div className="meetings-section">
      <Header />
      <div className="meetings-container">
        <h1 id="meetings-header">Scheduled Meetings</h1>
        <MeetingsOptions
          addMeeting={handleAddMeeting}
          meetings={appData.meetings[currentRoute] || []} // Ensure empty array as fallback
          currentRoute={currentRoute}
        />
        {appData.meetings[currentRoute] && appData.meetings[currentRoute].length > 0 ? (
          <FutureMeetings
            meetings={appData.meetings[currentRoute]}
            updateMeeting={handleUpdateMeeting}
            deleteMeeting={handleDeleteMeeting}
          />
        ) : (
          <div className="no-meetings">
            <p>No upcoming meetings scheduled. Use the button above to schedule new meetings.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export { MeetingsSection };
