import React, { useState } from 'react';
import { useEvents } from '../calendar/EventContext';
import '../../../styles/graphicComponents/clubs/ScheduleEvent.css'

function ScheduleEvent({ onClose, currentClub }) {
    const [eventDescription, setEventDescription] = useState('');
    const [eventDate, setEventDate] = useState('');
    const { addEvent } = useEvents();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (eventDescription && eventDate) {
            
            const eventDto = {
                date: eventDate,
                descriptions: `${currentClub} Club: ${eventDescription}`
              };

            addEvent(eventDto);

            alert('The event has been scheduled, please check it in the Calendar')
            setEventDescription('');
            setEventDate('');
            onClose();

        } else {
            alert('Please fill in all fields.');
        }
    };

    return (
        <div className="schedule-event-overlay">
            <div className="schedule-event-form">
                <div>
                    <button id="close-button" onClick={onClose}>X</button>
                </div>
                <form id="form" onSubmit={handleSubmit}>
                    <label id="input-1-label">
                        <p>Event Description:</p>
                        <input
                            type="text"
                            value={eventDescription}
                            onChange={e => setEventDescription(e.target.value)}
                            id="schedule-event-input-1"
                            required
                        />
                    </label>
                    <label id="input-2-label">
                        <p>Event Date:</p>
                        <input
                            type="date"
                            value={eventDate}
                            onChange={e => setEventDate(e.target.value)}
                            id="schedule-event-input-2"
                            required
                        />
                    </label>
                    <button type="submit" onClick={handleSubmit} className="schedule-event-button">Schedule Event</button>
                </form>
            </div>
        </div>
    );
}

export { ScheduleEvent };
