import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/graphicComponents/documents/DocumentsGroups.css';

function DocumentsGroups() {
    const navigate = useNavigate();

    const documentCategories = [
        { name: 'SEED-2022', title: 'SEED 2022 Documents' },
        { name: 'SEED-2023', title: 'SEED 2023 Documents' },
        { name: 'Academic', title: 'Academic Documents' },
        { name: 'Progress Reports 2024', title: 'Progress Reports 2024' },
    ];

    const handleNavigate = (category) => {
        navigate(`/documents/${category}`);
    };

    return (
        <div className="documents-groups-container">
            <div className="documents-groups-header">
                <h1 className="categories-header" >Document Categories</h1>
                <p>Select a category to view its documents. You can also add documents to support the research of SEED 2024 participants.</p>
            </div>
            <div className="documents-group-grid">
                {documentCategories.map(category => (
                    <button
                        key={category.name}
                        className="document-group"
                        onClick={() => handleNavigate(category.name)}
                    >
                        <img className="document-icon" src={`${process.env.PUBLIC_URL}/svg/document-icon.svg`} alt={category.title} />
                        {category.title}
                    </button>
                ))}
            </div>
        </div>
    );
}

export { DocumentsGroups };
