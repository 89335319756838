import React from "react";

function NotFound() {
  return (
    <div className="not-found">
      <h1>404 - Not Found</h1>
    </div>
  );
}

export { NotFound };