import React, { useState } from 'react';
import '../../../styles/graphicComponents/meetings/ScheduleMeeting.css';

const EditingMeeting = ({ meeting, onClose, updateMeeting, deleteMeeting }) => {
  const [updatedMeeting, setUpdatedMeeting] = useState({
    title: meeting.title,
    description: meeting.description,
    url: meeting.url,
    date: meeting.date,
    time: meeting.time,
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedMeeting((prevMeeting) => ({
      ...prevMeeting,
      [name]: value,
    }));
  };

  const validateDate = (dateStr) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateStr);
  };

  const handleUpdateMeeting = async () => {
    if (!updatedMeeting.title || !updatedMeeting.url || !updatedMeeting.date) {
      alert('Please fill in all fields to update the meeting.');
      return;
    }

    const dateParts = updatedMeeting.date.split('T')[0]; // Extracts the date part in 'YYYY-MM-DD' format
    if (!validateDate(dateParts)) {
      alert('Date must be in the format YYYY-MM-DD.');
      return;
    }

    updatedMeeting.time = updatedMeeting.date.split('T')[1]; // Extracts the time part in 'HH:MM' format

    await updateMeeting(meeting.id, updatedMeeting); // Call the passed update function
    onClose(); // Close the form after updating
  };

  const handleDeleteMeeting = async () => {
    await deleteMeeting(meeting.id); // Call the passed delete function
    onClose(); // Close the form after deleting
  };

  const handleClose = () => {
    onClose(); // Close the form without saving changes
  };

  return (
    <div id="schedule-meeting-overlay">
      <div id="schedule-meeting-container">
        <button id="close-button" onClick={handleClose}>X</button>
        <h2>Edit Meeting</h2>
        <div id="form-grid">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            name="title"
            value={updatedMeeting.title}
            onChange={handleInputChange}
            placeholder="Update the Title..."
          />

          <label htmlFor="description">Description:</label>
          <input
            type="text"
            name="description"
            value={updatedMeeting.description}
            onChange={handleInputChange}
            placeholder="Update the Description..."
          />

          <label htmlFor="url">Meeting Link:</label>
          <input
            type="text"
            name="url"
            value={updatedMeeting.url}
            onChange={handleInputChange}
            placeholder="Update the Meeting Link..."
          />

          <label htmlFor="date">Date (COL Time):</label>
          <input
            type="datetime-local"
            name="date"
            value={updatedMeeting.date}
            onChange={handleInputChange}
            placeholder="*Date in COL Time*"
          />
        </div>

        <button id="update-button" onClick={handleUpdateMeeting}>Update Meeting</button>

        <button
          id="delete-button"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete Meeting
        </button>

        {showDeleteConfirmation && (
          <div id="delete-confirmation">
            <p>Are you sure you want to delete this meeting?</p>
            <button onClick={handleDeleteMeeting}>Yes, Delete</button>
            <button onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditingMeeting };
