// EditingComment.js
import React, { useState, useEffect } from 'react';
import '../../../styles/graphicComponents/forums/EditingComment.css';

const EditingComment = ({ comment, updateComment, onClose }) => {
  const [content, setContent] = useState(comment.content);

  useEffect(() => {
    window.scrollTo({
      top: window.innerHeight / 2,
      behavior: 'smooth'
    });
  }, []);

  const handleUpdate = () => {
    const updatedComment = {
      ...comment,
      content,
    };
    updateComment(comment.id, updatedComment);
    onClose();
  };

  return (
    <div className="editing-comment-modal">
      <div className="editing-comment-content">
        <h2>Edit Your Comment</h2>
        <textarea
          id='editing-comment-textarea'
          value={content}
          onChange={e => setContent(e.target.value)}
          placeholder="Edit your comment"
        />
        <div className="editing-comment-buttons">
          <button id='cancel-comment-edit-button' onClick={onClose}>Cancel</button>
          <button id='save-comment-edit-button' onClick={handleUpdate}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default EditingComment;
