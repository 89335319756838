import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/graphicComponents/calendar/MyCalendar.css'; // Your custom CSS file for styling

import { FaPencilAlt } from 'react-icons/fa'; // Import the pencil icon
import { useAuth0 } from '@auth0/auth0-react';
import { useEvents } from '../calendar/EventContext';
import { NewTask } from './NewTask';
import { EditingEvent } from './EditingEvent'; // Import the EditingEvent component

function MyCalendar() {
  const [date, setDate] = useState(new Date());
  const { events, addEvent, updateEvent, fetchEvents } = useEvents(); // Include updateEvent for editing
  const { user, isAuthenticated } = useAuth0();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null); // State to manage editing

  // Fetch events on component mount
  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDayClick = (value) => {
    const selectedDate = value.toISOString().split('T')[0];
    const tasksForDate = events[selectedDate] || [];
    setSelectedTasks(tasksForDate);
  };

  const handleCloseClick = () => {
    setSelectedTasks([]);
  };

  const handleSaveTask = (event) => {
    const eventDto = {
      date: event.date,
      descriptions: event.descriptions,
    };
    addEvent(eventDto);
    setShowNewTaskForm(false);
  };

  const handleEditClick = (event) => {
    setEditingEvent(event);
  };

  const handleUpdateEvent = (updatedEvent) => {
    updateEvent(updatedEvent);
    setEditingEvent(null); // Close the editing form after updating
  };

  const handleNewTask = () => {
    setShowNewTaskForm(true);
  };

  const tileContent = ({ date, view }) => {
    const currentDate = date.toISOString().split('T')[0];
    return events[currentDate] ? <div className="highlight" /> : null;
  };

  const hasRole = (role) => {
    return user && user['https://my-intranet-app/roles'] && user['https://my-intranet-app/roles'].includes(role);
  };

  const isAdmin = isAuthenticated && hasRole('Admin');

  return (
    <div className={`calendar-container ${selectedTasks.length > 0 ? 'side-by-side' : 'centered'}`}>
      <h1 className='calendar-header'>SEED Calendar 🗓️</h1>
      <p className='calendar-subheader'>You can check here the important dates inside the SEED Program. Click on a date to see the planned events!</p>

      <div className="calendar-section">
        <Calendar
          onChange={setDate}
          value={date}
          onClickDay={handleDayClick}
          className="custom-calendar"
          locale="en-US"
          tileContent={tileContent}
        />
      </div>

      {selectedTasks.length > 0 && (
        <div className='tasks-main-container'>
          <div className={`task-popup ${selectedTasks.length > 0 ? 'active' : 'fade-out'}`}>
            <div className='close-button-container'>
              <button id="task-popup-close" onClick={handleCloseClick}>X</button>
            </div>
            <h2>{date.toDateString()}</h2>
            <ul>
              {selectedTasks.map((event, index) => (
                <li key={index}>
                  {event.descriptions}
                  {isAdmin && (
                    <FaPencilAlt
                      className="edit-icon"
                      onClick={() => handleEditClick(event)}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {isAdmin && (
        <div className="add-task-container">
          <button className="add-task-button" onClick={handleNewTask}>
            Add an Event
          </button>
        </div>
      )}

      {showNewTaskForm && (
        <NewTask onSave={handleSaveTask} onClose={() => setShowNewTaskForm(false)} />
      )}

      {editingEvent && (
        <EditingEvent
          event={editingEvent}
          onSave={handleUpdateEvent}
          onClose={() => setEditingEvent(null)}
          setSelectedTasks = {setSelectedTasks}
        />
      )}
    </div>
  );
}

export { MyCalendar };
