// ForumEntry.js
import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CommentSection } from './CommentSection';
import { IoArrowBack } from 'react-icons/io5';
import { FaPencilAlt } from 'react-icons/fa';
import '../../../styles/graphicComponents/forums/ForumEntry.css';
import { useForums } from './ForumsContext';
import { useComments } from './CommentsContext';
import EditingForum from './EditingForum'; // Import the EditingForum component

const ForumEntry = ({ currentUser }) => {
  const { id: forumId } = useParams();
  const navigate = useNavigate();
  const { forums, deleteForum, updateForum } = useForums();
  const { comments, isLoading, addComment, updateComment } = useComments();
  const [showLoading, setShowLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false); // State for showing the edit menu
  const [isEditing, setIsEditing] = useState(false); // State for showing the editing modal

  const forum = forums.find(f => f.id === forumId);

  const usersWhoLikedList = useMemo(() => forum?.usersWhoLiked || [], [forum]);

  useEffect(() => {
    if (forum) {
      setIsLiked(usersWhoLikedList.includes(currentUser.id));
    }
  }, [forum, usersWhoLikedList, currentUser.id]);

  const handleLike = () => {
    if (isLiked) {
      const updatedUsersWhoLiked = usersWhoLikedList.filter(id => id !== currentUser.id);
      updateForum(forum.id, { ...forum, usersWhoLiked: updatedUsersWhoLiked });
    } else {
      updateForum(forum.id, { ...forum, usersWhoLiked: [...usersWhoLikedList, currentUser.id] });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const filteredComments = comments.filter(comment => comment.forumId === forumId);

  const handleAddComment = (newComment) => {
    const commentDto = {
      authorID: currentUser.id,
      content: newComment.content,
      creationDate: newComment.creationDate,
      forumId: forumId,
    };
    addComment(commentDto);
  };

  const handleGoBack = () => {
    navigate('/forums');
  };

  const handleDeleteClick = (id) => {
    setConfirmDelete(id);
    setShowEditMenu(false);
  };

  const confirmDeleteForum = async (id) => {
    await deleteForum(id);
    navigate('/forums');
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setShowEditMenu(false);
  };

  if (!forum && showLoading) {
    return (
      <div className="fun-loading-animation">
        <div className="ball"></div>
        <p>Loading... Please wait!</p>
      </div>
    );
  }

  if (!forum && !showLoading) {
    return <div>Forum entry not found</div>;
  }

  return (
    <div className="forum-entry-page">
      <div className={`forum-entry-container ${isEditing ? 'blurred' : ''}`}>
        <button className="go-back-button" onClick={handleGoBack}>
          <IoArrowBack size={34} />
        </button>
        <h1 className="forum-title">{forum.title}</h1>
        <div className="forum-author">By {forum.author}</div>
        <div className="forum-content">
          <p id="forum-text-content">{forum.content}</p>
          {forum.images &&
            forum.images.map((img, idx) => (
              <img key={idx} src={img} alt={`Forum image ${idx + 1}`} />
            ))}
          <div id="creation-date">
            Creation Date:{' '}
            {new Date(forum.creationDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>

          <div id="heart-container">
            {isLiked ? (
              <img
                id="heart-svg"
                src={`${process.env.PUBLIC_URL}/svg/heart-filled.svg`}
                alt={'heart filled'}
                onClick={handleLike}
              />
            ) : (
              <img
                id="heart-svg"
                src={`${process.env.PUBLIC_URL}/svg/heart-unfilled.svg`}
                alt={'heart unfilled'}
                onClick={handleLike}
              />
            )}
            <p id="like-count">{usersWhoLikedList.length}</p>
          </div>

          {forum.author === currentUser.name && (
            <div id="edit-delete-container-forum">
              <FaPencilAlt
                id="pencil-icon-forum"
                onClick={() => setShowEditMenu(prev => !prev)}
              />
              <p id="edit-forum-text">Edit or Delete</p>
              {showEditMenu && (
                <div className="edit-menu-forum">
                  <button onClick={handleEditClick}>Edit</button>
                  <button onClick={() => handleDeleteClick(forum.id)}>Delete</button>
                  <button onClick={() => setShowEditMenu(false)}>Cancel</button>
                </div>
              )}
              {confirmDelete === forum.id && (
                <div className="confirm-delete">
                  <p>Are you sure you want to delete this post?</p>
                  <button onClick={() => confirmDeleteForum(forum.id)}>Yes</button>
                  <button onClick={() => setConfirmDelete(null)}>No</button>
                </div>
              )}
            </div>
          )}
        </div>
        <CommentSection
          comments={filteredComments}
          addCommentToForum={handleAddComment}
          updateComment={updateComment}
          currentUser={currentUser}
          isLoading={isLoading}
        />
      </div>
      {isEditing && (
        <EditingForum
          forum={forum}
          updateForum={updateForum}
          onClose={() => setIsEditing(false)}
        />
      )}
    </div>
  );
};

export { ForumEntry };
