import React from 'react';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import '../../../styles/graphicComponents/recommendations/RecommendationsSection.css'
import { RecommendationsPadlet } from './RecommendationsPadlet';
import { RecommendationsProvider } from './RecommendationsContext';

function RecommendationsSection() {

    
    return (
        <div className='recommendations-section-main-container'>
            <Header />

            <RecommendationsProvider>
                <div className='recommendations-padlet-container'>
                    <RecommendationsPadlet />
                </div>
            </RecommendationsProvider>
            
            <Footer />
        </div>
    );

}


export { RecommendationsSection } 