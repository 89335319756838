import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginWithRedirect({
        audience: 'https://gorom-intranet-api-endpoint/',
        scope: 'openid profile email',
      });

      // Set session expiration time for 1 week from now
      const expirationTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
      localStorage.setItem('sessionExpiration', expirationTime);
      
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return <button className="seed-button" onClick={handleLogin}>Log In</button>;
};

export { LoginButton };
