import React, { useState, useEffect } from 'react';
import { useMeetings } from './MeetingsContext'; // Adjust the import path as necessary
import { useAppData } from '../mainMenu/AppDataProvider'; // Import your app data provider to get user info
import '../../../styles/graphicComponents/meetings/ScheduleMeeting.css';

const ScheduleMeeting = ({ toggleScheduleMeeting, currentRoute }) => {
  const { addMeeting, fetchMeetings } = useMeetings();
  const { appData, loading } = useAppData(); // Get the current user's data
  const [newMeeting, setNewMeeting] = useState({
    title: '',
    description: '',
    url: '',
    date: '',
    time: '',
    classification: currentRoute || '',
  });

  useEffect(() => {
    if (!appData.currentUser) {
      // If no user data yet, return to wait for it
      return;
    }
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      authorId: appData.currentUser.goromLogin, // Set the authorId as the user's goromLogin email
    }));
  }, [appData.currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMeeting(prevMeeting => ({
      ...prevMeeting,
      [name]: value
    }));
  };

  const validateDate = (dateStr) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateStr);
  };

  const handleScheduleMeeting = () => {
    if (!newMeeting.title || !newMeeting.url || !newMeeting.date) {
      alert('Please fill in all fields to schedule a meeting.');
      return;
    }

    const dateParts = newMeeting.date.split('T')[0]; // Extracts the date part in 'YYYY-MM-DD' format
    if (!validateDate(dateParts)) {
      alert('Date must be in the format YYYY-MM-DD.');
      return;
    }

    newMeeting.time = newMeeting.date.split('T')[1]; // Extracts the time part in 'HH:MM' format

    const scheduledDate = new Date(newMeeting.date);
    const currentDate = new Date();
    if (scheduledDate <= currentDate) {
      alert('Please schedule the meeting for a future time!');
      return;
    }

    addMeeting(newMeeting);
    setNewMeeting({ title: '', description: '', date: '', classification: '', time: '', url: '', authorId: '' }); // To reset the form
    fetchMeetings();
    toggleScheduleMeeting(); // Close the form
  };

  const handleClose = () => {
    setNewMeeting({ title: '', description: '', date: '', classification: '', time: '', url: '', authorId: '' });
    toggleScheduleMeeting(); // Close the form
  };

  if (loading || !appData.currentUser) {
    return (
      <div className="fun-loading-animation">
        <div className="ball"></div>
          <p>Loading... Please wait!</p>
      </div>
    );
  }

  return (
    <div className="schedule-meeting-overlay">
      <div className="schedule-meeting-container">
        <button className="close-button" onClick={handleClose}>X</button>
        <h2>Schedule a New Meeting</h2>
        <input
          type="text"
          name="title"
          value={newMeeting.title}
          onChange={handleInputChange}
          placeholder="Please Add a Title to the meeting..."
        />
        <input
          type="text"
          name="description"
          value={newMeeting.description}
          onChange={handleInputChange}
          placeholder="Add a short description for the meeting..."
        />
        <input
          type="text"
          name="url"
          value={newMeeting.url}
          onChange={handleInputChange}
          placeholder="Paste the meeting link here..."
        />
        <input
          type="datetime-local"
          name="date"
          value={newMeeting.date}
          onChange={handleInputChange}
        />
        <button onClick={handleScheduleMeeting}>Schedule Meeting</button>
      </div>
    </div>
  );
};

export { ScheduleMeeting };
