import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/graphicComponents/recommendations/NewNoteForm.css';
import { useRecommendations } from './RecommendationsContext';
import { useAppData } from '../mainMenu/AppDataProvider';

function NewNoteForm({ onClose }) {
    const { addRecommendation } = useRecommendations();
    const { appData } = useAppData(); // Access the general context to get the currentUser
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('#ffeb3b'); // Default color
    const formRef = useRef(null);

    const currentUser = appData.currentUser;

    const handleAddNote = async () => {
        const author = currentUser ? currentUser.goromLogin : 'anonymous'; // The goromLogin being saved
        const name = currentUser ? currentUser.name : 'Anonymous';

        await addRecommendation({ 
            name,
            title, 
            description, 
            color, 
            author, // Store goromLogin in the author attribute
        });

        setTitle('');
        setDescription('');
        setColor('#ffeb3b'); // Reset to default color
        onClose();
    };

    useEffect(() => {
        const form = formRef.current;
        if (form) {
            const scrollY = window.scrollY || window.pageYOffset;
            const viewportHeight = window.innerHeight;
            const formHeight = form.clientHeight;
            const centerY = scrollY + (viewportHeight - formHeight) / 2;
            form.style.top = `${centerY}px`;
        }
    }, []);

    return (
        <div className="note-input" ref={formRef}>
            <button className="close-button" onClick={onClose}>X</button>
            <input 
                type="text" 
                placeholder="Title" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
            />
            <textarea 
                placeholder="Write your note here..." 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
            />
            <input 
                type="color" 
                value={color} 
                onChange={(e) => setColor(e.target.value)}
                className='color-picker'
            />
            <button onClick={handleAddNote}>Add Note</button>
        </div>
    );
}

export { NewNoteForm };
