import React, { useState } from 'react';
import { ScheduleMeeting } from './ScheduleMeeting';
import '../../../styles/graphicComponents/meetings/MeetingsOptions.css';

const MeetingsOptions = ({ addMeeting, currentRoute }) => {
  const [showScheduleMeeting, setShowScheduleMeeting] = useState(false);

  const toggleScheduleMeeting = () => {
    setShowScheduleMeeting(!showScheduleMeeting);
  };

  return (
    <div className="meetings-options-container">
      <div className="buttons-container">
        <button onClick={toggleScheduleMeeting}>Schedule a New Meeting</button>
      </div>
      {showScheduleMeeting && (
        <ScheduleMeeting
          addMeeting={(newMeeting) => {
            addMeeting(newMeeting); // Ensure this function modifies state outside and triggers a re-render
            setShowScheduleMeeting(false); // Close the schedule meeting component after adding a meeting
          }}
          toggleScheduleMeeting={toggleScheduleMeeting}
          currentRoute = {currentRoute}
        />
      )}
    </div>
  );
};

export { MeetingsOptions };
