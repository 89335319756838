import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState({});
  const [meetings, setMeetings] = useState({});
  const [documents, setDocuments] = useState({});
  const [forums, setForums] = useState({});

  const [appData, setAppData] = useState({ 
    
    // Data to pre-fetch
    currentUser: null,
    profiles: [],
    calendarEvents: [],
    recommendations: [],
    meetings: [],
    documents: [],
    forums: [],
    comments: []

  });

  const baseURL = 'https://seed-alumni.org/api/v1'; // Production
  //const baseURL = 'http://localhost:3001/api/v1'; // Development

  // Retry function with delay
  const retryFetch = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        const result = await fn();
        return result;
      } catch (error) {
        console.error(`Attempt ${i + 1} failed:`, error);
        if (i < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          throw error;
        }
      }
    }
  };

  // Fetch current user data with retry
  const fetchCurrentUser = async (email) => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/users/login/${email}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    });
  };

  // Fetch all profiles with retry
  const fetchAllProfiles = async () => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/users`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    });
  };

  // Fetch calendar events
  const fetchEvents = async () => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/events`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const eventsByDate = data.reduce((acc, event) => {
        const date = event.date.split('T')[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(event);
        return acc;
      }, {});
      setEvents(eventsByDate);
      return eventsByDate;
    });
  };


  // Fetch recommendations
  const fetchRecommendations = async () => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/recommendations`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    });
  };


  // Fetching meetings
  const fetchMeetings = async (classification = '') => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/meetings?classification=${classification}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const meetingsByClassification = data.reduce((acc, meeting) => {
        const classificationKey = meeting.classification;
        if (!acc[classificationKey]) {
          acc[classificationKey] = [];
        }
        acc[classificationKey].push(meeting);
        return acc;
      }, {});
      setMeetings(meetingsByClassification);
      return meetingsByClassification;
    });
  };


  // Fetch documents
  const fetchDocuments = async (category = '') => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/documents?category=${category}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const documentsByClassification = data.reduce((acc, doc) => {
        const classification = doc.classification;
        if (!acc[classification]) {
          acc[classification] = [];
        }
        acc[classification].push(doc);
        return acc;
      }, {});
      setAppData(prevData => ({
        ...prevData,
        documents: documentsByClassification,
      }));
      return documentsByClassification;
    });
  };

  
// Fetch forums
const fetchForums = async () => {
  return retryFetch(async () => {
    const response = await fetch(`${baseURL}/forums`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setAppData(prevData => ({
      ...prevData,
      forums: data,
    }));
    return data;
  });
};

  // Fetch comments
  const fetchComments = async () => {
    return retryFetch(async () => {
      const response = await fetch(`${baseURL}/comments`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAppData(prevData => ({
        ...prevData,
        comments: data,
      }));
      return data;
    });
  };


  // Fetch all necessary data when user logs in
  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && user) {
        try {
          const [currentUser, profiles, calendarEvents, recommendations, meetings, documents, forums, comments ] = await Promise.all([
            fetchCurrentUser(user.email),
            fetchAllProfiles(),
            fetchEvents(),
            fetchRecommendations(),
            fetchMeetings(),
            fetchDocuments(),
            fetchForums(),
            fetchComments()
          ]);
  
          setAppData({
            currentUser,
            profiles,
            calendarEvents,
            recommendations,
            meetings,
            documents,
            forums,
            comments
          });
  
        } catch (error) {
          console.error('Failed to fetch app data:', error);
        } finally {
          setLoading(false);
        }
      }
    };
  
    fetchData();
  }, [isAuthenticated, user]);
  

  return (
    <AppDataContext.Provider value={{ appData, loading, setAppData }}>
      {children}
    </AppDataContext.Provider>
  );
};
