import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/graphicComponents/recommendations/NotesGrid.css';
import { FaPencilAlt } from 'react-icons/fa';
import { useRecommendations } from './RecommendationsContext';
import { EditingNote } from './EditingNote';
import { useAppData } from '../mainMenu/AppDataProvider';
import { FunLoading } from '../Loading/FunLoading';

function NotesGrid({ notes }) {
    const { deleteRecommendation, fetchRecommendations, updateRecommendation } = useRecommendations();
    const { appData, loading } = useAppData(); // Access the general context to get the currentUser
    const [showMenu, setShowMenu] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const menuRef = useRef();


    const handlePencilClick = (id) => {
        setShowMenu(showMenu === id ? null : id);
        setConfirmDelete(null);
    };

    const handleDeleteClick = (id) => {
        setConfirmDelete(id);
    };

    const confirmDeleteNote = async (id) => {
        await deleteRecommendation(id);
        await fetchRecommendations(); // Re-fetch after deletion
        setShowMenu(null);
    };

    const handleEditClick = (note) => {
        setEditingNoteId(note.id);
        setShowMenu(null);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (loading || !appData.currentUser) {
        return (
            <div className="fun-loading-animation">
                <div className="ball"></div>
                    <p>Loading... Please wait!</p>
            </div>
        );
    }

    const currentUser = appData.currentUser;

    return (
        <div className="notes">
            {notes.map((note) => {

                // Compute usersWhoLikedList and isLiked per comment
                const usersWhoLikedList = note.usersWhoLiked || [];
                const isLiked = usersWhoLikedList.includes(currentUser.id);

                // Handle like per comment
                const handleLike = () => {
                    if (isLiked) {
                        // Unlike
                        const updatedUsersWhoLiked = usersWhoLikedList.filter(id => id !== currentUser.id);
                        updateRecommendation(note.id, { ...note, usersWhoLiked: updatedUsersWhoLiked });
                    } else {
                        // Like
                        updateRecommendation(note.id, { ...note, usersWhoLiked: [...usersWhoLikedList, currentUser.id] });
                    }
                };

            return (
                <div key={note.id} className="note" style={{ backgroundColor: note.color }}>
                    <h3 id='note-author'>{note.name}</h3>
                    <h3>_________________________________</h3>
                    <h3 id='note-title'>{note.title}</h3>

                    {note.author === currentUser.goromLogin && (
                        <FaPencilAlt id="pencil-icon" onClick={() => handlePencilClick(note.id)} />
                    )}

                    {showMenu === note.id && (
                        <div className="menu" ref={menuRef}>
                            <button onClick={() => handleEditClick(note)}>Edit</button>
                            <button onClick={() => handleDeleteClick(note.id)}>Delete</button>
                            <button onClick={() => setShowMenu(null)}>Cancel</button>
                            {confirmDelete === note.id && (
                                <div className="confirm-delete">
                                    <p>Are you sure?</p>
                                    <button onClick={() => confirmDeleteNote(note.id)}>Yes</button>
                                    <button onClick={() => setConfirmDelete(null)}>No</button>
                                    <button onClick={() => setShowMenu(null)}>Cancel</button>
                                </div>
                            )}
                        </div>
                    )}

                    <div className='note-description-container'>
                        <p id='note-description'>{note.description}</p>
                    </div>

                    {editingNoteId === note.id && (
                        <EditingNote 
                            note={note} 
                            onClose={() => setEditingNoteId(null)} 
                        />
                    )}


                    <div id='heart-container-note'>
                        <img
                            id='heart-svg-note'
                            src={`${process.env.PUBLIC_URL}/svg/heart-${isLiked ? 'filled' : 'unfilled'}.svg`}
                            alt={isLiked ? 'heart filled' : 'heart unfilled'}
                            onClick={handleLike}
                        />
                        <p id='like-count-note'>{usersWhoLikedList.length}</p>

                    </div>

                </div>

                );
            })}
        </div>
    );
}

export { NotesGrid };
