import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/graphicComponents/community/Groups.css';
import { useAppData } from '../mainMenu/AppDataProvider';

function Groups() {
    const navigate = useNavigate();
    const { appData, loading } = useAppData(); // Use the general context
    const groups = ['Professors', 'Directors', 'Coordinators', 'Participants'];

    if (loading || !appData.profiles.length) { // Check if profiles are still loading
        return (
            <div className="fun-loading-animation">
                <div className="ball"></div>
                <p>Loading... Please wait!</p>
            </div>
        );
    }

    return (
        <div className="groups-container">
            {groups.map(group => (
                <button
                    className="group-button"
                    key={group}
                    onClick={() => navigate(`/community/${group.toLowerCase()}`, { state: { profiles: appData.profiles } })}
                >
                    {group}
                </button>
            ))}
        </div>
    );
}

export { Groups };
