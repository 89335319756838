import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  
  useEffect(() => {
    const sessionExpiration = localStorage.getItem('sessionExpiration');
    const currentTime = new Date().getTime();

    if (sessionExpiration && currentTime > sessionExpiration) {
      logout({ returnTo: window.location.origin });
      localStorage.removeItem('sessionExpiration');
    } else if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, logout]);

  if ( isAuthenticated ) {
    console.log('User Authentication was validated');
  } else {
    console.log('User is not Authenticated');
  }

  return isAuthenticated ?
   Element
   : null;
};

export { PrivateRoute };
