import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../styles/graphicComponents/profile/OwnProfileDetails.css';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { FunLoading } from '../Loading/FunLoading';
import { useAppData } from '../mainMenu/AppDataProvider';
import { IoArrowBack } from 'react-icons/io5';

function ParticipantProfile() {
    const { id } = useParams(); // Get the ID from the URL params
    const { appData, loading } = useAppData(); // Use the general context
    const [participant, setParticipant] = useState(null);
    const navigate = useNavigate();

    // Fetch the participant from the general context or from the API if not found
    const fetchParticipant = async () => {
        const existingProfile = appData.profiles.find(profile => profile.id === id);
        if (existingProfile) {
            setParticipant(existingProfile);
        } 
    };

    useEffect(() => {
        if (id) {
            fetchParticipant(); // Fetch the participant's profile based on the ID
        }
    }, [id]);

    if (loading || !participant) {
        return <FunLoading />; // Display loading animation while data is being fetched
    }

    const handleGoBack = () => {
        navigate('/community');
      };

    // Split the interests string into an array
    const interestsArray = participant.interests ? participant.interests.split(',').map(interest => interest.trim()) : [];

    return (
        <div className="profile-section-main-container">
            <Header />

            <div className="profile-content-container">

                <button id="go-back-button" onClick={handleGoBack}>
                    <IoArrowBack size={34} />
                </button>

                <div className="profile-header">
                    <h1>{participant.name}</h1>
                    <img className='profile-picture' src={participant.picture} alt="Profile" />
                    <p>{participant.major}</p>
                    <p>{participant.university}</p>
                </div>
                <div className="profile-body">
                    <div className="profile-about">
                        <h2>About Me</h2>
                        <p id='participant-text'>{participant.about}</p>
                    </div>
                    <div className="profile-interests">
                        <h2>Interests</h2>
                        <p id='participant-text'>{participant.interests}</p>
                    </div>
                    <div className="profile-contact">
                        <h2>Contact</h2>
                        <p>Email: {participant.email}</p>
                        <p><a href={participant.linkedin}>LinkedIn</a></p>
                    </div>
                    <div className="profile-team">
                            <h2>My Team</h2>
                            <p id="participant-text">{participant.team}</p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export { ParticipantProfile };
