import React, {useEffect} from 'react';
import '../../../styles/graphicComponents/clubs/ClubsGrid.css';
import { useNavigate } from 'react-router-dom';
import { useClubs } from './ClubsContext';

const ClubsGrid = () => {
    const navigate = useNavigate();
    const { clubs, fetchClubs } = useClubs();

    useEffect(() => {
        fetchClubs();
      }, []);

    const handleClubClick = (ClubComponent) => {

        let clubRoute= clubs.find(club => club.name === ClubComponent).route;

        const path = `/clubs/${clubRoute}`;
        navigate(path);
    };

    return (
        <div className="clubs-grid">
            {clubs.map((club, index) => (
                <button
                    key={club.id}
                    className={`club-option gradient-${index % 8}`}
                    onClick={() => handleClubClick(club.name)}
                >
                    <img className="club-icon" src={club.iconUrl} alt={club.name} />
                    <p>{club.name}</p>
                </button>
            ))}
        </div>
    );
};

export { ClubsGrid };
