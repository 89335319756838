// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, logout, user } = useAuth0();
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });

  useEffect(() => {
    if (!isLoading) {
      setAuthState({
        isAuthenticated,
        user,
      });
    }
  }, [isAuthenticated, isLoading, user]);

  return (
    <AuthContext.Provider value={{ ...authState, loginWithRedirect, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
