import React, { useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import '../../../styles/graphicComponents/meetings/FutureMeetings.css';
import { EditingMeeting } from './EditingMeetings';

const FutureMeetings = ({ meetings, updateMeeting, deleteMeeting }) => {
  const currentDate = new Date();
  const numGradients = 5;
  const [editingMeetingId, setEditingMeetingId] = useState(null);

  const handleEditClick = (meeting) => {
    setEditingMeetingId(meeting.id);
  };

  const handleCloseEditing = () => {
    setEditingMeetingId(null);
  };

  const handleMeetingClick = (url) => {
    window.open(url, '_blank');
  };

  const formatDateInTimeZone = (date, hoursToAdd) => {
    const timeZoneDate = new Date(date);
    timeZoneDate.setHours(timeZoneDate.getHours() + hoursToAdd);
    return {
      date: timeZoneDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }),
      time: timeZoneDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };
  };

  return (
    <div className={`future-meetings-container ${editingMeetingId ? 'blur-background' : ''}`}>
      {meetings.map((meeting, index) => {
        const colTime = formatDateInTimeZone(meeting.date, 0);
        const argBraTime = formatDateInTimeZone(meeting.date, 2);
        const jpTime = formatDateInTimeZone(meeting.date, 14);

        return (
          <div
            key={meeting.id}
            className={`meeting-card gradient-${index % numGradients} ${new Date(meeting.date) < currentDate ? 'past-meeting' : 'future-meeting'}`}
            onClick={() => handleMeetingClick(meeting.url)}
          >
            <h3 className="meeting-title">{meeting.title}</h3>
            <p className="meeting-date"><strong>COL Time:</strong> {colTime.date}, {colTime.time}</p>
            <p className="meeting-date"><strong>ARG/BRA Time:</strong> {argBraTime.date}, {argBraTime.time}</p>
            <p className="meeting-date"><strong>JP Time:</strong> {jpTime.date}, {jpTime.time}</p>
            <p className="meeting-description">{meeting.description}</p>

            <FaPencilAlt
              id="pencil-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(meeting);
              }}
            />
          </div>
        );
      })}

      {editingMeetingId && (
        <div className="editing-meeting-overlay">
          <EditingMeeting
            meeting={meetings.find(meeting => meeting.id === editingMeetingId)}
            onClose={handleCloseEditing}
            updateMeeting={updateMeeting}
            deleteMeeting={deleteMeeting}
          />
        </div>
      )}
    </div>
  );
};

export { FutureMeetings };
