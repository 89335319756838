import React, { useState, useEffect } from 'react';
import '../../../styles/graphicComponents/recommendations/RecommendationsPadlet.css';
import { NewNoteForm } from './NewNoteForm';
import { NotesGrid } from './NotesGrid';
import { useRecommendations } from './RecommendationsContext';
import { useAppData } from '../mainMenu/AppDataProvider';

function RecommendationsPadlet() {
    const { addRecommendation, updateRecommendation, deleteRecommendation, fetchRecommendations } = useRecommendations();
    const { appData, setAppData } = useAppData(); // Access the general context to get pre-fetched data
    const [showForm, setShowForm] = useState(false);
    const [editingNote, setEditingNote] = useState(null);
    const [notes, setNotes] = useState(appData.recommendations); // Initialize with pre-fetched data

    useEffect(() => {
        setNotes(appData.recommendations); // Sync local notes with context data
    }, [appData.recommendations]);

    const refreshNotes = async () => {
        const updatedRecommendations = await fetchRecommendations();
        setAppData((prevData) => ({ ...prevData, recommendations: updatedRecommendations }));
        setNotes(updatedRecommendations); // Update local notes state to trigger re-render
    };

    const addNote = async (newNote) => {
        if (editingNote !== null) {
            await updateRecommendation(editingNote, newNote);
            setEditingNote(null);
        } else {
            await addRecommendation(newNote);
        }
        await refreshNotes(); // Refetch and update notes after adding or updating a note
        setShowForm(false);
    };

    const deleteNote = async (id) => {
        await deleteRecommendation(id);
        await refreshNotes(); // Refetch and update notes after deleting a note
    };

    const editNote = (id) => {
        setEditingNote(id);
        setShowForm(true);
    };

    return (
        <div className="padlet-main-container">
            <h1 className='recommendations-header'>Recommendations from Participants</h1>
            <h3 id="indications-text">You can add a recommendation with the + button at the end of all the posted notes!</h3>
            {showForm && <div className="overlay" />}
            <NotesGrid notes={notes} deleteNote={deleteNote} editNote={editNote} />
            {showForm && <NewNoteForm addNote={addNote} onClose={() => setShowForm(false)} />}
            
            <div className='add-note-container'>
                <button className="add-note-button" onClick={() => setShowForm(true)}>+</button>
            </div>
        </div>
    );
}

export { RecommendationsPadlet };
