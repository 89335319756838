import React from 'react';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { DocumentsGroups } from './DocumentsGroups';

import '../../../styles/graphicComponents/documents/DocumentsSection.css';
import { DocumentsProvider } from './DocumentContext';

function DocumentsSection() {
    return (
        <DocumentsProvider>
            <div>
                <Header />
                <DocumentsGroups />
                <Footer />
            </div>
        </DocumentsProvider>
    );
}

export { DocumentsSection };
