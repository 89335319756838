import React, { useState } from 'react';
import { useEvents } from '../calendar/EventContext';
import '../../../styles/graphicComponents/calendar/EditingEvent.css';

const EditingEvent = ({ event, onSave, onClose, setSelectedTasks }) => {
  const { updateEvent, deleteEvent } = useEvents(); // Get update and delete functions from context
  const [updatedEvent, setUpdatedEvent] = useState({
    date: event.date,
    descriptions: event.descriptions,
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State to manage delete confirmation

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedEvent(prevEvent => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    updateEvent(event.id, updatedEvent); // Call the context's updateEvent function
    onSave(updatedEvent);
    setSelectedTasks([]);
  };

  const handleDeleteClick = () => {
    deleteEvent(event.id); // Call the context's deleteEvent function
    setSelectedTasks([]);
    onClose(); // Close the editing form after deleting
  };

  return (
    <div id="editing-event-overlay">
      <div id="editing-event-container">
        <button id="close-button" onClick={onClose}>X</button>
        <h2>Edit Event</h2>
        <div id="form-grid">
          <label htmlFor="descriptions">Description:</label>
          <input
            type="text"
            name="descriptions"
            value={updatedEvent.descriptions}
            onChange={handleInputChange}
            placeholder="Update the Description..."
          />

          <label htmlFor="date">Date:</label>
          <input
            type="date"
            name="date"
            value={updatedEvent.date}
            onChange={handleInputChange}
          />
        </div>
        <button id="save-button" onClick={handleSaveClick}>Save Changes</button>

        <button 
          id="delete-button" 
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete Event
        </button>

        {showDeleteConfirmation && (
          <div id="delete-confirmation">
            <p>Are you sure you want to delete this event?</p>
            <button onClick={handleDeleteClick}>Yes, Delete</button>
            <button onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditingEvent };
