import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocuments } from '../documents/DocumentContext';
import { AddDocument } from './AddDocument';
import '../../../styles/graphicComponents/documents/DocumentGrid.css';

function DocumentGrid() {
    const { documents, fetchDocuments } = useDocuments();
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filter, setFilter] = useState('');
    const [showAddDocument, setShowAddDocument] = useState(false);

    // Getting the current relative route
    const location = useLocation();
    const currentRouteName = location.pathname;

    // Fetch documents based on the current route as classification
    useEffect(() => {
        fetchDocuments(currentRouteName);
    }, [currentRouteName, fetchDocuments]);

    // Filter documents based on the input filter
    useEffect(() => {
        if (documents[currentRouteName]) {
            const lowerCaseFilter = filter.toLowerCase();
            const filtered = documents[currentRouteName].filter(doc =>
                doc.name.toLowerCase().includes(lowerCaseFilter)
            );
            setFilteredDocuments(filtered);
        }
    }, [filter, documents, currentRouteName]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleDocumentClick = (link) => {
        window.open(link, '_blank');
    };

    


    return (
        <div className="documents-container">
            <input
                type="text"
                placeholder="Search by document name..."
                className="document-search-input"
                onChange={handleFilterChange}
                value={filter}
            />
            <div className="documents-grid">
                {filteredDocuments.map((doc, index) => (
                    <div key={index} className={`document-option gradient-${index % 8}`} onClick={() => handleDocumentClick(doc.url)}>
                        <img src={`${process.env.PUBLIC_URL}/svg/document-icon.svg`} alt={doc.name} className="document-icon" />
                        <p>{doc.name}</p>
                    </div>
                ))}
                <button className="add-document-button" onClick={() => setShowAddDocument(true)}>+</button>
            </div>
            {showAddDocument && (
                <AddDocument setShowAddDocument={setShowAddDocument} onClose={() => setShowAddDocument(false)} currentRoute={currentRouteName} />
            )}
        </div>
    );
}

export { DocumentGrid };
