import React from 'react';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { MyCalendar } from './MyCalendar';
import '../../../styles/graphicComponents/calendar/MyCalendarSection.css'
import { EventsProvider } from '../calendar/EventContext';

function MyCalendarSection() {

    //Logic Functions

    
    return (
        <div className='calendar-section-main-container'>
            <Header />

            <EventsProvider>
                <div className='my-calendar-container'>
                    <MyCalendar />
                </div>
            </EventsProvider>
    
            <Footer />
        </div>
    );

}


export { MyCalendarSection } 