import React from 'react';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

import { OwnProfileDetails } from './OwnProfileDetails';
import { ProfilesProvider } from './ProfilesContext';

function ProfileSection() {

    return (
        <div>
            <Header />

            <ProfilesProvider>
                <OwnProfileDetails />
            </ProfilesProvider>
        
            <Footer />
        </div>
    );
}

export { ProfileSection };
