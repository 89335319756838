import React, { createContext, useState, useContext, useEffect } from 'react';

const DocumentContext = createContext();

// Custom hook for using context
export const useDocuments = () => {
  const context = useContext(DocumentContext);
  if (context === undefined) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }
  return context;
};

export const DocumentsProvider = ({ children }) => {
  const [documents, setDocuments] = useState({});

  // Backend URL
  const baseURL = 'https://seed-alumni.org/api/v1/documents';

  //const baseURL = 'http://localhost:3001/api/v1/documents'; // Development URL

  const fetchDocuments = async (category = '') => {
    try {
      const response = await fetch(`${baseURL}?category=${category}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const documentsByClassification = data.reduce((acc, doc) => {
        const classification = doc.classification;
        if (!acc[classification]) {
          acc[classification] = [];
        }
        acc[classification].push(doc);
        return acc;
      }, {});
      setDocuments(documentsByClassification);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
      return [];
    }
  }; 

  const addDocument = async (documentDto) => {
    console.log('Sending document:', documentDto);  // Log the DTO being sent
    const response = await fetch(`${baseURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(documentDto),
    });
    if (response.ok) {  // Check if the POST request was successful
      fetchDocuments();  // Refetch all documents
    }
    return response;
  };

  const updateDocument = async (id, documentDto) => {
    const response = await fetch(`${baseURL}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(documentDto),
    });
    if (response.ok) {  // Check if the PUT request was successful
      fetchDocuments();  // Refetch all documents to update the state
    }
    return response;
  };

  const deleteDocument = async (id) => {
    const response = await fetch(`${baseURL}/${id}`, {
      method: 'DELETE',
    });
    if (response.ok) {  // Check if the DELETE request was successful
      fetchDocuments();  // Refetch all documents to remove the deleted document from state
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <DocumentContext.Provider value={{ documents, addDocument, updateDocument, deleteDocument, fetchDocuments }}>
      {children}
    </DocumentContext.Provider>
  );
};
